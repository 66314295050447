// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("channels");

require("bootstrap");
require("jquery");
require("jquery.easing");

require("select2");
require("select2/dist/js/i18n/es");
require("select2/dist/js/i18n/en");
require("air-datepicker");
require("air-datepicker/src/js/i18n/datepicker.en");
require("air-datepicker/src/js/i18n/datepicker.es");

window.toastr = require("toastr");
let locale;

import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/account";

$(() => {
  $('input.rut-input').on('input', ({ target }) => {
    // const value = target.value.toUpperCase().replace(/[^0-9K\-]/g, '').slice(-9);
    // const formatted = target.classList.contains('no-dash') ? value : `${value.replace(/[\-]/g, '').slice(0,-1)}-${value.slice(-1)}`;
    const value = target.value.toUpperCase().replace(/[^0-9K]/g, '').slice(-10);
    const formatted = `${value.slice(0,-1)}-${value.slice(-1)}`;
    target.value = formatted;
  });
});